.stickyNavbar {
    z-index: 5;
    position: sticky;
    top: -1px;
    transition: .2s ease-out;
    overflow: hidden;

    &--isSticky {
        background-color: white;
        color: var(--highlight);
        border: 1px solid var(--highlight);
        border-radius: 0 0 10px 10px;

        .stickyNavbar__item {
            padding: 0 10px;
            cursor: pointer;

            &:hover {
                background-color: var(--highlight);
                color: white;
            }
        }
    }

    margin: 5px 0;

    &__items {
        display: flex;
        gap: 10px;
        list-style-type: none;
        margin: unset;
        padding: unset;
        height: 50px;
        line-height: 50px;
    }

    &__item {
        padding: 0 10px;
        cursor: pointer;

        &:hover {
            background-color: var(--highlight);
            color: white;
        }

        &--active {
            background-color: var(--highlight);
            color: white;
        }
    }

    &__cohortPills {
        display: flex;
        gap: 10px;
        margin: 0 8px;
    }

    &__cohortPill {
        padding: 5px 10px;
        background-color: var(--highlight);
        color: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 8px 0;
    }

    &__cohortPillClose {
        cursor: pointer;
    }

    @media screen and (max-width: 440px) {
        .stickyNavbar__items {
            width: 440px;
        }
    }
}