@import './../styleResources/colors';

// Graph Loader
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// PlayerCardLoader
@mixin loadingBlockAnimation
{
    background: linear-gradient(90deg, var(--background), #ffffff, var(--background));
    background-size: 200% 200%;
    -webkit-animation: Animation 2s linear infinite;
    -moz-animation: Animation 2s linear infinite;
    animation: Animation 2s linear infinite;
}

@-webkit-keyframes Animation {
    0%{background-position:90% 100%}
    50%{background-position:50% 50%}
    100%{background-position:0% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:90% 100%}
    50%{background-position:50% 50%}
    100%{background-position:0% 0%}
}
@keyframes Animation { 
    0%{background-position:90% 100%}
    50%{background-position:50% 50%}
    100%{background-position:0% 0%}
}