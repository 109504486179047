.userProfileCard {
    text-align: center;
    align-items: center;
    justify-content: space-around;

    &__name {
        font-size: 2.5rem;
        font-weight: 700;
    }

    &__info {
        display: flex;
        gap: 2rem;
        margin: 0;
    }

    &__totalScore {
        font-size: 3rem;
    }

    &__weekMonthNum {
        font-size: 2rem;
    }

    &__classNum {
        padding-top: 10px;
        text-align: center;
        font-size: 1.75rem;
    }

    &__weekAndMonths {
        display: flex;
        gap: 1rem;
    }

    &__border {
        padding-right: 1em;
        border-right: 1px solid #D3D3D3;
    }

    &__infoTitle {
        color: #787878;
        padding-bottom: .75em;
    }

    &__weekAndMonthsTitle {
        color: #787878;
        font-size: 1rem;
    }

    @media screen and (max-width: 440px) {
        display: none;
    }
}

