.trackProjectInstructions{
    a div {
        margin: 1px 0;
        padding: 3px;
        border-radius: 2px;; 
    }

    ol li{
        margin: 30px 0 0 0;
        font-weight: 600;
    }

    img {
        width: 400px;
        max-width: 100%;
    }
}