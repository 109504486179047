@import '../../styleResources/colors';

.barGraph {
    background-color: white;
    z-index: 20;
    height: 220px;
    color: $gray-dark;

    &__menu {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-left: 10px;
    }

    &__dataTypes {
        display: flex;
        align-items: center;
        padding: 0;
        gap:10px;

        > * {
            cursor: pointer;
            height: 40px;
            line-height: 40px;
        }
    }

    &__togglePoints-active {
        color: rgb(var(--secondary-highlight));
        font-weight: 600;
    }
    &__toggleCommits-active {
        color: var(--background);
        font-weight: 600;
    }

    &__container {
        display: flex;
    }

    &__scale {
        color: black;
        height: 165px;
        text-align: right;
        padding-right: 3px;
        > * {
            padding-bottom: 20px;
        }
    }

    &__background {
        margin-top: 9px;
        
    }

    &__row {
        display: flex;
        &:nth-child(4){
            &>* {
                border-bottom: 1px solid $gray-light !important;
            }
            &>:first-child {
                border-bottom: 2px solid black !important;
            }
        }
        &:last-child {
            height: 5px;
            & > * {
                height: 5px;
                border-bottom: none;
                border-left: none;
                border-top: none;
                border-left: 2px solid black
            }
            & > *:nth-child(2){
                border-left: none;
            }
            & > *:last-child {
                border-right: none;
            }
        }
    }

    &__backgroundCell {
        width: 12%;
        height: 38px;
        border-left: 1px solid $gray-light;
        border-top: 1px solid $gray-light;
        margin-bottom: -1px;
        &:first-child {
            border-top: 2px solid black;
            border-left: none;
            width: 3%;
            height: 37px;
        }

        &:nth-child(2){
            width: 7%;
        }

        &:last-child {
            border-right: 1px solid $gray-light;
            width: 7%;
        }
    }

    &__bars {
        position: relative;
        display: flex;
        bottom: 155px;
        align-items: end;
        left: 61px;
        min-height: 148px;  
        > * {
            width: 4%;
            background-color: rgb(var(--secondary-highlight));
            &.commits {
                background-color: var(--background);
            }
            &:not(:last-child){
                margin: 0 1.9% 0 0;
            }
        }
        &.both {
            left: 54px;
            > * {
                width: 18.5px;
                background-color: rgb(var(--secondary-highlight));
                
                &.commits {
                    background-color: var(--background);
                }
                
                &:nth-child(odd){
                    margin: 0 .74% 0 0 !important;
                }

                &:nth-child(even){
                    margin: 0 8.5px 0 0;
                }

                &:last-child{
                    margin: 0;
                } 
            }
        }
    } 

    &__dates {
        display: flex;
        color: black;
        font-size: 12px;
        bottom: 144px;
        position: relative;
       
        > * :last-child{
            padding-right: 0 !important;
        }
    }
}

.playerCard .barGraph {
    &__bars {
        left: 46px;
        > * {
            width: 8%;
            &:not(:last-child){
                margin: 0 10px 0 0;
            }
        } 
        
        &.both > * {
            width: 4%;
            &:nth-child(odd){
                margin: 0 3px 0 0 !important;
            }
            &:nth-child(even){
                margin: 0 6px 0 0;
            }
        }  
    }
}   

@media only screen and (max-width: 440px)  {
    .barGraph {

        &__bars {
            > * { 
                &:not(:last-child){
                    margin: 0 10px 0 0;
                }
            }
            &.both {
                > * {
                    &:nth-child(odd){
                        margin: 0 3px 0 0;
                    }
    
                    &:nth-child(even){
                        margin: 0 7.5px 0 0;
                    }
                }
            }
        }
        &__dates {
            padding-left: 48px;
        }
    }
}