.modal {
  padding: 20px 35px;
  border-radius: 10px;
  position: fixed;
  background: white;
  top: 160px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0);
  width: 70%;
  max-width: 400px;
  box-shadow: 3px 3px 15px -10px;
  //   &::before{
  //     bottom: 100%;
  //     border: solid transparent;
  //     content: " ";
  //     height: 0;
  //     width: 0;
  //     position: absolute;
  //     pointer-events: none;
  //     border-color: rgba(113, 158, 206, 0);
  //     border-bottom-color: white;
  //     border-width: 6px;
  //     right: 17px;
  //     margin-left: -6px;
  //   }

  &__closeIcon {
    color: gray;
    position: absolute;
    right: 10px;
    padding: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__skipButton {
    color: black;
    position: absolute;
    right: 10px;
    padding:  15px 20px;
    bottom: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  &__title {
    color: black;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 1.2em;

  }

  &__content {
    line-height: 1.2em;
    font-size: 1.1em;

  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 99;
}