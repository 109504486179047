.inviteMembers {
    &__textArea {
        width: calc(100% - 10px);;
        height: 100px;
        font-size: 18px;
        padding: 5px;
    }

    &__buttonDiv {
        display: flex;
        justify-content: end;
    }
}