.heroBlock {
    &__leftContainer {
        margin: 120px 0px;
       

        h1 {
            font-family: 'DM Sans', sans-serif;
            font-size: 58px;
            font-weight: 700;
            line-height: 64px;
            letter-spacing: -1px;
            color: #15154F;
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            font-weight: 510;
            line-height: 28px;
            color: #747474;
            opacity: 0.7;
        }

        .btn-purple {
            width: 150px;
            height: 55px;
            margin-top: 30px;
            text-transform: none !important; 
        }
    }

    &__rightContainer img {
        height: 600px;

    }

}
