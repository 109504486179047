@import '../../styleResources/colors';
@import '../../styleResources/layouts';

$navbar_height: 75px;
$background-color: white;
$primary-color: var(--background);

.left {
    float: left;
  }

.right {
float: right;
}

nav {
    height: $navbar_height;
    background-color: white;
    display: inline-block;
    width: 100%;

    // border-bottom: 1px solid rgba(18, 17, 56, 0.5);
    // padding: 0 10px;
    
    .nav-items {
      max-width: 1200px;
      margin: 0 auto;
      // height: $navbar_height;
      line-height: $navbar_height;
     
      .expanded-menu-items { 
        padding: 0; 
      }

      ul {
        list-style: none;
        margin: 0;
        .active li:not(.highlight){
          // text-decoration: underline;
          color: $purple;
        }
        a {
          text-decoration: none;
          color: inherit;
          .btn-purple{
            margin-left: 16px;
          }
          .btn-transparent-outline{
            margin-left: 20px;
          }
          &:hover{
            li{
              color: $purple;
            }
          }
        }
        li {
          text-align: center; 
          padding: 0 20px;
          display: inline-block;
          color: #121138;
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
          opacity: 0.8;
          &.highlight {
            background-color: $primary-color;
            color: $background-color;
          }
        }
      }
      .logo_container {
        position: relative;
        height: $navbar_height;
      }
      .logo {
        height: 50px;
        padding-top: 10px;
        max-width: 260px;
      }

      .logo-text {
        color: black;
        font-size: 1.5rem;
        font-weight: 600;
      }


    }

    .condensed-menu-icon {
      cursor: pointer;
    }

    @media only screen and (max-width: 960px)  {
      .expanded-menu-items {
        display: none;
        &.active {
          display: block;
          background-color: white;
          clear: both;
          width: 100%;
          position: absolute;
          top: $navbar_height;
          border-bottom: 1px solid black;
          z-index: 10;
          li { 
            display: block;
            line-height: $navbar_height / 2;
          }
        }
      }
    }

    @media only screen and (min-width: 959px)  {
      .condensed-menu-icon { display: none; }
    }

  }
  .expanded-menu-items .mobile-menu{
    display: none;
  }

  @media (max-width: 991px) {

  nav{
    .nav-items {
      display: flex;
      width: calc(100% - 20px);
      justify-content: space-between;
      align-items: center;
      .logo_container{
        img{
          width: 100%;
        }
      }
    }
    .condensed-menu-icon{
      color: #121138;
      width: 25px;
      height: 43px;
      padding: 0px 10px;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    .expanded-menu-items {
      &>a{
        display: none;
      }
      &.active{
        position: fixed;
        left: 0;
        top: 75px;
        height: calc(100vh - 75px);
      }
      .mobile-menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: calc(100% - 80px);
        padding: 30px 20px;
        .navigation-link {
          width: 100%;
          .register{
            ul{
              li{
                a{
                  color: #3F51B5;
                  font-weight: 500;
                }
              }
            }
          }
          .social-icons{
              display: none;
              flex-direction: row;
              justify-content: center;
              li{
                padding: 0 8px;
                a{
                  background: #12113866;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 4px;
                }
              }
          }
          ul{
            display: flex;
            flex-direction: column;
            padding: 0;
            border-bottom: 1px solid #1211383d;
            padding-top: 25px;
            li{
              margin-bottom: 24px;
              a{
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
