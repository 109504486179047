@import './../../styleResources/colors';


.challengeCollection {

    .problemSetHeader {
        padding: 10px 20px;
        background-color: $elephant;
        border-radius: 5px;
        margin: 10px 0;
        font-weight: 600;

        &:hover {
            color: $purple; 
            filter: drop-shadow(2px 2px 4px darken($elephant, 20%));
        }

        &__title {
          padding-right: 40px;
        }
    }
}

.codewarsChallenge{
    &__videoUrl {
        margin-left: 20px;           
    }
}