.profileInfo {
    margin-bottom: 1em;
}

.sideTabs {
    margin-right: 40px;
}

.challengeLists {
    width: 700px;
}

.dashboard__barChart {
   margin-bottom: 20px; 
}

@media only screen and (max-width: 440px){
    .portfolio, .codeChallenges, .accountSettings, .profileInfo {
        width: auto;
    }
    .dashboard__barChart {
        display: none;
    }
}