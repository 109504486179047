.logicAssessment {
    width: 1200px;
    margin: 0 auto;
    padding: 5px 20px 20px;
    background-color: white;
   
    .tryTwoColumns {
        display: flex;
        justify-content: space-between;
    }

    &__clues {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        
    }
    &__clue {
        font-size: 2em;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 3px;
        img {
            width: 100px;
            height: 100px;
        }
    }

    &__challenge {
        padding-bottom: 20px;
    }

    &__answer {
       outline: none;
       border: none;
       border-bottom: 2px solid black;
       font-size: 1.2em;
       font-weight: bold;
       width: 50px;
       margin: 0 20px;
       text-align: center;
    }

    &__rating{
        font-size: 1.2em;
        & > * {
            margin-bottom: 5px;
        }
    }

    &__submit {
        margin-top: 20px;
        padding: 20px 30px;
        border: none;
        border-radius: 3px;
        background-color: #4CAF50;
        color: white;
        font-size: 1.2em;
        font-weight: bold;
        float: right;
        cursor: pointer;

        &--small {
            padding: 10px 20px;
        }

    }
}