.inviteList {

    &__headers{
        display: flex;
        margin: 0 115px;
        > * {
            width: 25%;
        }
    }
     ul {list-style-type: none;}
    &__item {
        display: flex;
        > * {
            width: 33%;
        }
    }
}