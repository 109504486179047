.codeTrackIntroVideo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;;

    &__textContainer {
        width: 803px;
        height: 280px;
        margin: 10px;

        h3 {
            font-family: 'DM Sans', sans-serif;
            font-size: 42px;
            font-weight: 700;
            line-height: 52px;
            letter-spacing: -0.4px;
            text-align: center;
            color: #15154F;
            margin-top: 60px;
            margin-bottom: 20px;
        }

        p {
        
            font-size: 16px;
            opacity: 0.7;
            font-weight: 510;
            line-height: 28px;
            text-align: center;
            color: #747474;
            margin-bottom: 20px;
        }

        h4 {
            font-family: 'DM Sans', sans-serif;
            font-size: 26px;
            font-weight: 700;
            line-height: 28px;
            text-align: center;
            color: #151439CC;
            margin: 20px;
        }
    }

    &__videoPlaceholder {
        width: 650px;
        height: 400px;
        background: #E9E9E9;
        border-radius: 20px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__circleContainer {
            width: 63px; 
            height: 63px;
            border-radius: 50%; 
            border: 1.5px solid #4242EA;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            &__playButton {
                width: 25.83px;
                height: 25.83px;
            }
        }
    }

    &__signup {
        .btn-purple {
            width: 140px;
            height: 55px;
            margin: 40px;
            text-transform: none !important; 
        }

        
    }
}


