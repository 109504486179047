.howDoesItWork_battingCageModal {
    display: flex;
    align-items: center;
    height: 100%; 
    overflow: hidden; 

    &__image {
        margin-right: 30px; 
    }
   


    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        width: 100%; 
        margin-bottom: 20px;
        
        
        &__text {
            max-width: 280px; 
            height: 130px; 
            text-align:start;
            color:  #747474;
            font-size: 12px;
        }

        &__buttons {
            display: flex;
            flex-wrap: wrap; 
            gap: 20px;
            margin: 20px 0px;
            

            .btn-purple {
                width: 120px;
                height: 38px;
                font-size: 12px !important;
           
            }

            .learnMore {
                background: #E1E1F7 !important;
                color: #4242EA !important;
    

                &:hover {
                    background-color: #ffffff !important;
                }
            }
        }
    }


    .howDoesItWork_battingCageModal__content__title {
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        color: #15154F;
        height: 35px;
    }


    .battingCageModal__alternativeContainer {
        position: absolute;
        top: 515px;
        left: 50%;
        transform: translateX(-50%);
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
       

        p {
            width: 100%;
            height: 20px;
            color: white;
            text-align: center;
            font-size: 17px;
        }

        .battingCageModal__imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;

        }
    }
}