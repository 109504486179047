.howDoesItWork {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;

    .howDoesItWork__top {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 902px;


        .howDoesItWork__heading {
            font-size: 42px;
            font-weight: 600;
            line-height: 52px;
            letter-spacing: -0.4px;
            color: #15154F;
            margin: 0;
        }

        .howDoesItWork__subheading {
            font-size: 18px;
            opacity: 0.7;
            font-weight: 500;
            line-height: 28px;
            color: #747474;
            width: 60%;
        }
    }

    .howDoesItWork__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin-top: 20px;
        width: 740px;

        .howDoesItWork__divider {
            width: 2px;
            background-color: #EBEAED;
            height: 360px;
        }

        .howDoesItWork__left,
        .howDoesItWork__right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;

            .btn-purple {
                width: 140px;
                height: 50px;
                margin-top: 20px;
                text-transform: none !important;
            }

            .howDoesItWork__text {
                width: 300px;
                height: 160px;

                .howDoesItWork__title {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 32px;
                    margin: 10px;
                }

                .howDoesItWork__description {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #747474;
                }
            }

            .howDoesItWork__imageContainer {
                width: 300px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                .howDoesItWork__imagesRow {
                    width: 80px;
                }
            }
        }

        .howDoesItWork__left {
            .howDoesItWork__circularBorder {
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 4px solid #1E0E62;
                margin: auto;
            }
        }
    }
}