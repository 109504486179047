.portfolio {
    & > * {
        margin-bottom: 20px;
    }
    &__titleContainer {
        display: flex;
        justify-content: space-between;
        svg {
            font-size: 2em;
            font-weight: 600;
            color: gray;
            padding: 10px;
        }
    }

    &__settings {
        cursor: pointer;
    }
    
    &__title {
        font-size: 2.5em;
        font-weight: 600;
    }

    &__subtitle {
        font-size: 1.4em;
        font-weight: 600;
        color: gray;
    }

    &__link {
        font-size: 1.2em;
        margin: 8px 0;
        display: inline-block;
        width: 30%;
        span {
            font-weight: 600;
        }
    }

    &__mainImg {
        width: 400px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .leftCol {
        width: 60%;
        display: inline-block;
        vertical-align: top;
    }

}

@media (max-width: 440px) {
    .portfolio {
      &__mainImg {
        padding-right: 0;
        max-width: 100%;
        float: none;
      }
    }
}