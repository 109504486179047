@import '../../styleResources/layouts';
@import '../../styleResources/colors';

.badgeList {
    &__container{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @media only screen and (max-width: 440px) {
            grid-template-columns: repeat(4, 1fr);
            width: calc(100% - 30px);  
        }
        & > * {
            width: 75px;
        }
    }
}