.emptyView {
    text-align: center;
    font-weight: 600;
    height: 100px;
    line-height: 150px;

    @media only screen and (max-width: 440px) {
        position: unset !important;
        line-height: 100px;
    }
}