.twoColumns {
    padding: 30px 0;

    &__container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 20px;
        margin-top: 0;
    }
    &__left {
        height: 100%;
        padding: 0;
    }
    &__right {
        height: 100%;
        margin: 0;
    }

    @media only screen and (max-width: 440px) {
        &__container {
            grid-template-columns: 1fr;
        }

        &__left {
            margin: 0 10px;
        }
        &__right {
            display: none;
        }
    }
}