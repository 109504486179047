@import '../styleResources/layouts';

.pageContent {
    padding: 2em 4em;
    width: 100%;
    & > * {
        margin-bottom: 1em;
    }
}

.fellowDetailLayout {
    display: flex; 
}

.profileInfo {
    @include three-column-grid(2, 1.3, 3);
}

.sideMenu {
    margin-top: 30px;
    height: 100%;
    background-color: var(--background);
    width: 50px;
    border-radius: 25px;
    color: white;   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1px 0;
    &__icon {
        padding: 10px 5px;
        margin: 4px;
        &:hover {
            cursor: pointer;
            border-radius: 30px;
            background-color: var(--text-primary);
            color: var(--background)
        }
        &--selected {
            border-radius: 30px;
            background-color: var(--text-primary);
            color: var(--background);
        }
    }
}

@media only screen and (max-width: 440px){
    .sideMenu {
        display: block;
        height: 30px;
        width: auto;
        flex-direction: row;
        margin: 0 0 10px 0;
        padding: 10px 20px;
        &__icon {
            padding: 4px 4px;
            margin: 0 5px;
        }
    }

    .pageContent {
        padding: 0;
    }

    .profileInfo {
        display: block;
        > * {
            margin-bottom: 15px;
        }
    }

    .fellowDetailLayout {
        display: block; 
    }
}