.portfolioProjectForm {
    &__submit {
        display: flex;
        justify-content: end;
        margin-top: 10px;
        button {
            width: 200px;
        }
    }

    &__submitLoader-spinning {
        animation: spin-animation 1.2s infinite;

    }
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

// @media screen and (max-width: 440px){
//     .portfolioProjectForm__links {
//         display: block;
//     }
// }