@import '../../styleResources/layouts';
@import '../../styleResources/colors';

.playerCardCollection {
    position: relative;
    &__container{
        @extend %three-column-grid;
    }

    &__header {
        @extend %three-column-grid;
        @media only screen and (max-width: 440px)  {
            display: block;
        }
    }

    &__filter, &__order {
        margin: 12px 0;
    }
    &__filter {
        display: flex;
        justify-content: space-between;
    }
    
    &__showEmployed {   
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__showEmployedText {   
        color: $textBlue;
        font-size: 12px;;
    }

    &__pagination {
        margin-top: 20px;
        background-color: unset;
        display: flex;
        justify-content: space-around;
    }
}

.playerCardCollection__loadMore {
    background-color: var(--background);
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: 600;
    border-radius: 10px;
    height: 210px;

    &:hover {
        filter: drop-shadow(3px 3px 5px darken($elephant, 40%));
    }
}