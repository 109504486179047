.playerStats {

    text-align: center;
    display: inline-block;

    &:first-child{
        margin-right: 10px;
    }
    &__title {
        font-weight: 600;
        font-size: 12px;
    }
    &__lastMonth {
        margin-top: 10px;
    }

    &__points {
        font-size: 1.6em;
        font-weight: 800;
        &--small {
            font-size: 1.4em;
        }
    }

    &__lastWeek, &__lastMonth {
        position: relative;
    }
    &__lastWeekLabel, &__lastMonthLabel {
        font-size: 10px;
        padding-top: 2px;
    }
}