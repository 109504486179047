@import '../../styleResources/layouts';

.memberListItem {
    &__text {
        width: 100%;
        > * {
            float: left;
            width: 25%;
        }
    }
    &__actionsContainer > button {
        margin: 0 8px;
    }
}

@-moz-keyframes rotate { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotate { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }