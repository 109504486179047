@import '../../styleResources/colors';

.sync-codewars-account img {
    width: 100%;
}

.sync-codewars-account .btn-purple {
    width: 100px;
    height: 47px;
    text-transform: none !important;
}

.sync-codewars-account .get-started-btn {
    width: 140px;
    height: 50px;
}

.sync-codewars-account .btn-disabled {
    background-color: #BDBDBD !important; 
    color: white !important; 
    cursor: not-allowed !important; 
}

.copy-url {
    border: 1px solid gray;
    border-radius: 7px;
    padding: 10px;
    display: inline-flex;
    align-items: center;
}
.suggested-problems {
    list-style: none;
    width: 80%;

    li {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 25px;
        align-items: center;
        padding-left: 5px;
        cursor: pointer;
        color: black;
        a {
            color: black;
        }
        a:hover {
            color: $textBlue;
        }
    }
}