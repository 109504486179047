@import '../../styleResources/colors.scss';

.jobsList {
    border: 1px #d9d9d9 solid;
    border-radius: 10px;
    grid-row-start: 3;  
    grid-row-end: 4;
    grid-column-start: 3;

    &__title {
        text-transform: uppercase;
        padding: 15px 20px;
        border-bottom: 1px #ccc solid;
        font-weight: 600;
        font-size: 13px;
        .red-circle {
          border: 2px $darkPurple solid;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;
        }
      }
  
    &__locations {
      padding: 10px;
      outline: none;
      border-radius: 4px;
      border: 0;
      border-right: 10px solid transparent;
      cursor: pointer;
      color: #8c8c8c;
      font-weight: 600;
    }

    &__container {
        transition: height .5s ease;
    }
  
    &__item {
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 3fr 2.5fr;
      grid-gap: 6px;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      &-hidden {
        display: none;
      }
    }
  
    &__brandImg img {
        width: 50px;
        border-radius: 3px;
    }
  
    &__brand {
      font-size: 0.8em;
      color: gray;
      font-weight: 600;
    }
  
    &__content {
      font-size: 0.8em;
      font-weight: 600;
      line-height: 17px;
    }
  
    &__itemButton {
      background-color: var(--background);
      color: white;
      padding: 5px 10px;
      font-size: .9em;
      font-weight: 600;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      a {
        color: white;
        text-decoration: none;
      }
      &:hover {
        background-color: var(--highlight);
      }
    }
    
    &__toggle {
      padding: 15px;
      color: #1578FF;
      text-align: center;
      font-size: .8em;
      font-weight: 600;
      cursor: pointer;
    }
    
    @media only screen and (max-width: 440px) {
      grid-row-start: unset;  
      grid-row-end: unset;
      grid-column-start: unset;
      display: none;
    }
  }
  