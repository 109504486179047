.toggle {
   
    position: relative;
    display: inline-block;
    top: 10px;
    width: 45px;
    height: 20px;

    input[type="checkbox"] {
        display: none;
    }
    
    &__switch {
        position: absolute;
        cursor: pointer;
        background-color: #ccc;
        border-radius: 25px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: background-color 0.2s ease;

        &:before {
            position: absolute;
            content: "";
            width: 20px;
            margin: auto;
            bottom: 0;
            top: 1;
            height: 20px;
            background-color: var(--background);
            border-radius: 50%;
            top: .05px;
            border: .1px solid rgb(131, 127, 127);
            transition: transform 0.3s ease;
        }
    }
}

.toggle input[type="checkbox"]:checked + .toggle__switch:before {
    transform: translateX(25px);
    background-color: #ccc;
    top: .05px;
    border: .1px solid rgb(202, 202, 202);
}
.toggle input[type="checkbox"]:checked + .toggle__switch {
    background-color: var(--background);
}