body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: #00645E;
  --highlight: #10857e;
  --secondary-background: #9c1cd1;
  --secondary-highlight: 156,28,209;
  --text-primary: white;
  --text-secondary: #2E106A;
  --accent: #FBDD59;
}

[data-theme='pursuit'] {
  --background: #4242ea;
  --highlight: #4b4bf2;
  --secondary-background: #9c1cd1;
  --secondary-highlight: 251,33,239;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

[data-theme='marcylab'] {
  --background:#327a5f;
  --highlight: rgb(34,116,181);
  --secondary-background: #c92929;
  --secondary-highlight:  238,188,50;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

[data-theme='codepath'] {
  --background: #255D70;
  --secondary-background: #8E1570;
  --text-primary: white;
  --text-secondary:#03C98E;
  --accent: #133A45;
  --sun: #FFD166;
}
