.classSelector {
    padding-top: 15px;
  
    &__title {
        width: 230px;
        font-weight: 600;
        cursor: pointer;
    }

    // &__container {
    //     display: none;
    // }

    @media only screen and (max-width: 440px)  {
        overflow: auto;
        white-space: nowrap;
    }
}


