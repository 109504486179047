.org-settings {
    
    &__grid {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        margin-bottom: 15px;
    }

    &__button {
        width: 50%;
        float: right;
    }
}

@media screen and (max-width: 440px) {
    .org-settings {

        &__grid {
            display: block !important;
        }

        &__grid > * {
            margin-bottom: 15px !important;
            width: 100%;
        }

        &__button {
            width: 100%;
        }
    }
}