@import './../../styleResources/colors';

.codilityChallengesCollection {

    .MuiButtonBase-root {
        margin-left: 10px;
    }

    .MuiOutlinedInput-input {
        padding: 10px 12px !important;
       
    }
    
    a {
       margin: 10px 20px 10px 0;
    }

    .codilityChallenge{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__rank {
            color: grey
        }
    }
}

