// @import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 14px;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  margin-top: 0;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover > .arrow:after {
  content: "";
  border-width: 10px;
}
.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}
.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #ffffff;
  border-bottom-width: 0;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}
.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #ffffff;
  border-left-width: 0;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #ffffff;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #ffffff;
}
.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}
.popover-content {
  padding: 9px 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}
.clearfix:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}


.battingCage {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto 60px;

    &__howToPlay {
        display: flex;
        justify-content: end;
        gap: 20px;
        margin: 20px auto;
    }

  &__gameArea {
    background-color: white;
    border-radius: 15px;
    margin-top: 20px;
  }
  &__gameAreaHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 2px solid #F8F8FB;;
  }

  &__title {
    font-size: 1.5em;
  }

  &__count {
    display: flex;
    > * {
        border: 1px solid #bebebd;
        padding: 5px;
        border-radius: 5px;
        margin-right: 10px
    }
  }
}

.popover {
  max-width: unset;
}

.game-stats {
  padding-right: 40px;
}

.tag {
  font-size: 10pt;
  font-family: Monospace;
  white-space: pre;
}

.ace-monokai .ace_comment {
  color: white;
}

.ace_placeholder { opacity: .9;}

.battingHistory {
    position: relative;
    transition: bottom .5s;
}

.ace_editor{
    display: inline-block;
    border-top: 1px solid rgb(176, 172, 172);
    border-bottom: 1px solid rgb(176, 172, 172);
  }

.play-again {
  border: 2px dotted black;
  padding: 10px;
  width: 200px;
  margin: 0 auto;
}

#code_input{
  height: auto !important;
}
.ace_scroller{
  padding: 10px 0;
}

.semi-bold {
  font-weight: 600;
}

.blurred {
  filter: blur(3px);
}

.center {
  text-align: center;
}

.test-case {
  display: inline-block;
  padding-right: 5px;
}

.game-intro {
  width: 30%;
  margin: 0 auto;
  padding: 20px 0;
  &--buttons {
    padding: 40px 0;
    display: flex;
    justify-content: space-around;
  }
}

.button-container {
  display: flex;
  justify-content: right;
  padding: 20px 0;
  gap: 10px;
}

.game-over-report {
  padding: 20px 0;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
}

.sm-btn {
  padding: 0 10px;
  border-radius: 3px;
  color: white;
  background-color: black;
  line-height: 28px;
}

.pointer{
  cursor: pointer;
}

.modal__content {
  width: 90%;
}

.success_battingCaseModalImage {
  background: url(../../assets/images/batting-cage-success.png);
  max-width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
}

.success_battingCaseModalOptions {
  width: 100%;
  display: flex;
  gap: 2em;
  flex-direction: column;
  justify-content: space-between;
  height: max-content;
  margin-top: 2em;
}
.success_battingCaseModalButtons {
  display: flex;
  align-items: center;
  gap: 1em;
}

.success_battingCaseModalTitle {
  text-align: left;
  line-height: 1.5;
  font-size: 40px;
}

.success_battingCageModal__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
  padding: 1em;
}

.success_battingCageModal__content_btn {
  border: none;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 0.75em;
  font-weight: 501;
  letter-spacing: 0.55px;
  cursor: pointer;
}

.success_battingCageModal__content_btn:hover {
  opacity: 0.5;
}

.success_battingCageModal__content_btn.left {
  background-color: #dfdaff;
  color: #1500ff;
}
.success_battingCageModal__content_btn.right {
  background-color: #4800ff;
  color: white;
}

@media screen and (max-width: 768px) {
  .success_battingCageModal__content {
    grid-template-columns: none;
    gap: 0.5em;
    padding: 1em;
    place-items: center;
    justify-content: center;
    align-items: center;
  }

  .success_battingCaseModalImage {
    background: url(../../assets/images/batting-cage-success.png);
    max-width: 200px;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
  }
}