.battingHistoryItem  {
    border-bottom: blue solid 1px;
    line-height: 50px;
    padding: 0 10px;
    &:first-child {
      border-top: none;
      padding-top: 30px;
    }
    &:last-child {
      border-bottom: none;
    }

    &__status {
        float: right;
    }
  }