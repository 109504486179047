@import '../../styleResources/layouts';
@import '../../styleResources/colors';

.sales {

    &__miniTitle{
        text-align: center;
        // width: 50%;
        padding-bottom: 0;
        margin: 0 auto 24px;
        font-size: 20px;
        font-weight: 600;
        color: $purple;
    }

    &__title {
        text-align: center;
        // width: 50%;
        margin: 0 auto;
        padding-bottom: 24px;
        font-size: 56px;
        line-height: 64px;
        font-weight: 600; 
        i{
            font-weight: 700;
            color: $purple;
        } 
    }

    &__subtitle {
        text-align: center;
        // width: 50%;
        margin: 0 auto;
        margin-bottom: 50px;
        color: #121138;
        font-size: 20px;
        line-height: 32px;
        opacity: 0.8;
        // letter-spacing: 2px;
    }

    &__text {
        font-size: 20px;
        letter-spacing: 1.1px;
        // width: 80%;
        margin: 0 auto;
        margin-bottom: 20px
    }

    &__callToAction {
        text-align: center;
        // width: 50%;
        margin: 0 auto 30px auto;
    }

    &__salesPoints {
        margin-top: 40px;
    }

    &__salesCards {
        margin-top: 40px;
        @extend %three-column-grid;
        @media only screen and (max-width: 440px)  {
            display: block;
        }
    }

    .salesCard {
        .salesImage {
            width: 250px;
            height: 172px;
            border-radius: 8px;
            border: 1px solid rgba(25,4,69,0.1);
            box-shadow: 0px 4px 10px rgb(25, 4, 69);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top left;
            margin-bottom: 40px;
            background-image: url("https://via.placeholder.com/269x212");
        }
        width: 250px;
        @media only screen and (max-width: 440px)  {
            width: 100%; 
            
            .salesImage {
                margin: 0 auto;
            }
        }
    }

    @media only screen and (max-width: 440px)  {
        &__title, &__miniTitle, &__subtitle, &__callToAction, &__text{
            width: 100%;    
        }

        &__title {
            font-size: 2.6em;
        } 
    }
}

.card-info {
    padding: 15px;
    max-width: 600px;
    min-height: 300px;
    margin: 0 auto;
    background-color: #3f51b5;
    box-shadow: 0px 4px 10px rgb(25, 4, 69);
    border-radius: 10px;
    color: white;
      .name {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          padding-top: 10px;
      }
      .role {
          font-size: 14px;
          text-align: center;      
          padding-top: 10px;      
      }
      .content{
          font-weight: 400;
          font-family: Circular,Helvetica,sans-serif;
          font-size: 18px;
          line-height: 1.1;
          padding: 15px 0;
          background-color: unset;
      }
  }