.battingHistoryList {
    height: 400px;
    margin: 0 auto;
    justify-content: flex-end;
    overflow-y: scroll;
  
    &__title {
      font-size: 64px;
      padding-bottom: 60px;
      font-weight: 700;
    }
  }