.atBatContainer {
    &__problemInfo {
      background: #084ea3;
      display: flex;
      justify-content: space-between;
      width: 90%;
      color: white;
      border-radius: 0 0 7px 0;
      font-size: 12px;
      padding: 4px 10px;
      line-height: 26px;
      margin-bottom: 10px;
      
      &.batting{
        position: sticky;
        top: 0;
      }
      &.out{
        background: red;

        &:after {
          background: red;
        }
      }
      &.score{
        background: green;
      }
    }
  
    width: auto;
    position: relative;
    border-top: #084ea3 solid 1px;
  
    &:after {
      content: "";
      background: #084ea3;
      position: absolute;
      top: 0;
      left: 0;
      height: 90%;
      width: 6px;
      border-radius: 0 0 7px 0;
    }

    &.out:after {
      background: red;
    }

    &.score:after {
      background: green;
    }
    
    &__strikes { 
      padding: 0 10px;
      font-size: 17px;
      line-height: 35px;
    }
  
    &__cases {
      background-color: white;
      color: #084ea3;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;

      &-surging {
          // add animation to surge in size
          animation: surge 0.8s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
  
          @keyframes surge {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(1.05);
            }
          }
      }
    }
  
    &__prompt {
      padding: 10px 0;
      font-size: 1.2em;
    }
  
    &__baseball-hint {
      border: 1px solid white;
      border-radius: 14px;
      width: 30px;
      height: 28px;
      margin-top: 4px;

      .svg-inline--fa.fa-lg {
        vertical-align: -0.35em;
      }

      &-surging {
         // add animation to surge in size
        animation: surge 0.5s ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @keyframes surge {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.2);
          }
        }
      }
  
    &.score:after {
      background: green;
    }
  
    &.out:after {
      background: red;
    }
    }
     
  }
  