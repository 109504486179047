.currentProblem {
    border-top: 2px solid #F8F8FB;
    margin: 0 auto;
    display: flex;
    padding: 10px 20px;
    
    &__input, &__output {
      border-radius: 10px 0 0 10px;
      border: 1px solid rgb(176, 172, 172);
      padding: 10px;
      background-color: #F8F8FB;
      font-size: 17px;
      flex: none;
    }
  
    &__output {
      border-radius: 0 10px 10px 0;
    }
  }