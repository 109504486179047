.howDoesItWork_battingCageIntroModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 420px;
    margin: 45px 35px;

    &__title {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        color: #15154F;
        margin: 10px;
    }

    &__content { 
        font-size: 13px;
        width: 80%; 
        margin: 15px auto;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 20px 0;
        justify-content: flex-start;

        .btn-purple {
            width: 130px;
            height: 38px;
            font-size: 13px !important;
        }

        .videoTutorial {
            background: #E1E1F7 !important;
            color: #4242EA !important;

            &:hover {
                background-color: #ffffff !important;
            }
        }
    }

    .battingCageIntroModal__alternativeContainer {
        position: absolute;
        top: 505px;
        left: 50%;
        transform: translateX(-50%);
        width: 450px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            width: 100%;
            height: 20px;
            color: white;
            text-align: center;
            font-size: 17px;
        }

        .battingCageIntroModal__imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 210px;
            height: 50px;
        }
    }
}

.howDoesItWork_battingCageIntroModal__leftColumn,
.howDoesItWork_battingCageIntroModal__rightColumn {
    ul {
        li {
            margin-bottom: 13px;
        }
    }
}

.howDoesItWork_battingCageIntroModal__rightColumn {
    width: 42%; 
    padding: 10px;

    span {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        color: #15154F;
    }
}

.howDoesItWork_battingCageIntroModal__leftColumn {
    width: 45%;
    padding: 10px;
}
