.videos {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }
}

@media screen and (max-width: 440px) {
    .videos {
        &__grid {
            grid-template-columns: 1fr;
        }
    }
    
}