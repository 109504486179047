$overflow-var: visible;

body {
  background-color: #F8F8FB;
}

.App {
  min-height: calc(100vh - 260px);
}

.home, .accountSettings {
  max-width: 1200px;
  margin: 30px auto 40px auto;
}


@media only screen and (max-width: 440px) {
  .home {  margin: .5em; }
}

a {
  text-decoration: none;
}

.how-to-use {
  li {
    margin-bottom: 10px;
  }
  margin-bottom: 60px;
}

.pageContainer {
  margin: .5em;
}

body {
  overflow: var(--overflow-var);
}

.overlay {
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(130, 130, 130, 0.9); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.overlay >* {
  pointer-events: none;
}

.codeTrack__loading {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-text-icon {
  margin-left: 10px;
  cursor: pointer;
}