@import '../../styleResources/colors.scss';

.articleList {
    border: 1px #ccc solid;
    margin: 0 auto;
    border-radius: 10px;
    grid-row-start: 1;  
    grid-row-end: 3;
    grid-column-start: 3;
    
    &__title {
      text-transform: uppercase;
      padding: 15px 20px;
      border-bottom: 1px #ccc solid;
      font-weight: 600;
      font-size: 13px;

      a {
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }

      .purple-circle {
        border: 2px $darkPurple solid;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
    }

    img {
      width: 100%;
      height: 190px;
      justify-content: center;
    }
    
    &__list {
      padding: 20px;
      
      .article {
        display: grid;
        grid-template-columns: 1fr 8fr;
        padding-top: 10px;
        cursor: pointer;
        &:not(:last-child){
          border-bottom: 1px #ccc solid;
          padding-bottom: 10px;
        }
        
        
        &__number {
          color: $darkPurple;
        }
        
        &__title {
          font-weight: 600;
        }
      }
    } 

    @media only screen and (max-width: 440px) {
      grid-row-start: unset;  
      grid-row-end: unset;
      grid-column-start: unset;
    }
}