@import '../../styleResources/colors';

.points-breakdown {
  
    font-size: 12px;

    &__source {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    &__leetcode-problems{
        margin-bottom: 20px;
        td {
            padding: 0 10px;
        }
    }
}