.cssChallenge {
    &__headline {
        font-size: 2.25rem;
        line-height: 1.139;
        font-weight: 600;
    }

    &__headlineDescription {
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
        line-height: 1.44;
    }

    &__image {
        max-width: 100%;
    }
}