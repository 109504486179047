@import '../../styleResources/layouts';
@import '../../styleResources/colors';

.topScoresCollection {
    position: relative;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__showStandings {   
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 45px;
    }

    &__showStandingsText {   
        color: $textBlue;
        font-size: 12px;;
    }

    &__container{
        @extend %three-column-grid;
    }
}