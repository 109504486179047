.articles {
  width: 1200px;
  margin: 40px auto;

  .shadowBox__title {
    font-size: 1.8em;
    font-weight: 600;
  }

  &__list {
    display: grid;
    grid-template-columns:  1fr 1fr;
    gap: 15px;
    
  }
}

@media screen and (max-width: 440px) {
  .articles {
    width: 90%;
    margin: 0 auto;
    &__list {
      grid-template-columns:  1fr;
    }
  }

}