.singleBadge{
    width: 75px;
    height: auto;
    display: flex;
    justify-content: center;
    

    img {
        max-width: 100%; 
        height: 75px;
    }
} 