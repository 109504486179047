.orgAccountManagement {
    max-width: 1200px;
    margin: 0 auto;
}

@media screen and (max-width: 440px) {
    .orgAccountManagement {
        margin: 0.5em;
    }
}

