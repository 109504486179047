@import './../../styleResources/colors';
@import './../../styleResources/layouts';

.scoreBoard {
    background-color: var(--background);
    color: white;
    border-radius: 10px;
    padding: 10px;
    width: inherit;

    @extend %four-column-grid;

    &__title,  &__subtitle {
        font-weight: 600;
        font-size: 28px;
    }

    &__subtitle {
        font-size: 18px;
    }

    &__singleScore {
        font-size: 19px;
        padding-top: 10px;
    }

    span {
        font-weight: 600;
    }

    @media only screen and (max-width: 440px)  {
        &__singleScore {
            font-size: 18px;
        }
    }
}