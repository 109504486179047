.commitsLog {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
  }
  table {
    width: 100%;
    thead {
      background-color: #4d6799;
      th {
        color: white;
        font-size: 1rem;
      }
    }
    tr.odd {
      background-color: lightseagreen;
    }
    tr.feedback {
      background-color: #ddd;
    }
  }

  &__repoName {
    font-weight: 600 !important;
  }

  &__loadingView {
    text-align: center;
    font-weight: 700;
    margin: 0 auto;
    padding-top: 40px;
  }
}
