@import '../../styleResources/layouts';
@import '../../styleResources/fonts';

.memberList {

    &__count {
        padding-top: 20px;
        span { 
            font-weight: 600;
            margin-right: 10px;
        }
    }

    &__sorting {
        @extend %four-column-grid;
    }
    &__tableHeaderRow > th {
        @extend %bold;
    }
}
.memberList, .inviteList {
    padding-bottom: 30px;
}