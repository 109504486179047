.playerDetailCard {

    &__totalPoints {
        font-size: 2.3em;
        font-weight: 800;
        padding: 25px 0 40px 0px;
    }

    &__cohort { 
        font-size: 14px;;
    }

    &__stats {
        display: flex;
        gap: 20px;
    }

    @media screen and (min-width: 440px) {
        display: none;
    }
}