@import 'colors';
@import 'fonts';
body{
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    color: #121138;
}

.container{
    max-width: 1200px;
    margin: 15px auto;
}

.btn-transparent-outline{
    background-color: transparent !important;
    color:$purple !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border: 1px solid $purple !important;
    border-radius: 12px !important;
    padding: 14px 24px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    opacity: 1 !important;
    &:hover{
        background-color: $purple !important;
        color: #fff !important;
    }
}
.btn-purple{
    background-color: $purple !important;
    color:#fff !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border: 1px solid $purple !important;
    border-radius: 12px !important;
    padding: 14px 24px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    opacity: 1 !important;
    &:hover{
        background-color: #fff !important;
        color: $purple !important;
    }
}
%page-layout {
    margin: .5em;
    & > * {
        margin-bottom: 10px;
    }
}

%three-column-grid {
    display: grid;
    column-gap: 2rem;
    row-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 10px;

    @media only screen and (max-width: 440px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
      }
}

%four-column-grid {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: 440px) {
        grid-template-columns: 1fr 1fr;
      }
}

%two-column-grid {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: 4fr 1fr;
}

@mixin two-column-grid($firstColumn: 4, $secondColumn: 1) {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: $firstColumn+fr $secondColumn+fr;
}

@mixin three-column-grid($firstColumn: 1, $secondColumn: 1, $thirdColumn: 1) {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: $firstColumn+fr $secondColumn+fr $thirdColumn+fr;
}
.createProblemSet{
    margin-bottom: 50px;
}
@media (max-width: 1199px) {
    .container{
        max-width: 960px;
    }
}
@media (max-width: 991px) {
    .container{
        max-width: 700px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 767px) {
    .container{
        max-width: 100%;
    }
}