.accountSettings {
  & > * {
    margin-bottom: 1em;
  }
}

.acctInfo,
.deleteAcct {
  &__submit {
    display: flex;
    justify-content: end;
    margin-top: 10px;

    button {
      width: 200px;
    }
  }
}

.accountSettings .modal button {
  line-height: 1.2;
}

.accountSettings .modal button:nth-of-type(1) {
  margin-right: 10px;
}
