%bold {
    font-weight: 600;
}
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Regular'), url('../assets/fonts/Inter-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: normal;
    src: local('Inter Italic'), url('../assets/fonts/Inter-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: local('Inter Thin BETA'), url('../assets/fonts/Inter-Thin-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    src: local('Inter Thin Italic BETA'), url('../assets/fonts/Inter-ThinItalic-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: local('Inter Extra Light BETA'), url('../assets/fonts/Inter-ExtraLight-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter ';
    font-style: italic;
    font-weight: 200;
    src: local('Inter Extra Light Italic BETA'), url('../assets/fonts/Inter-ExtraLightItalic-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: local('Inter Light BETA'), url('../assets/fonts/Inter-Light-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter ';
    font-style: italic;
    font-weight: 300;
    src: local('Inter Light Italic BETA'), url('../assets/fonts/Inter-LightItalic-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local('Inter Medium'), url('../assets/fonts/Inter-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    src: local('Inter Medium Italic'), url('../assets/fonts/Inter-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local('Inter Semi Bold'), url('../assets/fonts/Inter-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter ';
    font-style: italic;
    font-weight: 600;
    src: local('Inter Semi Bold Italic'), url('../assets/fonts/Inter-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    src: local('Inter Bold'), url('../assets/fonts/Inter-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: bold;
    src: local('Inter Bold Italic'), url('../assets/fonts/Inter-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: local('Inter Extra Bold'), url('../assets/fonts/Inter-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter ';
    font-style: italic;
    font-weight: 800;
    src: local('Inter Extra Bold Italic'), url('../assets/fonts/Inter-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter ';
    font-style: normal;
    font-weight: 900;
    src: local('Inter Black'), url('../assets/fonts/Inter-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    src: local('Inter Black Italic'), url('../assets/fonts/Inter-BlackItalic.woff') format('woff');
    }