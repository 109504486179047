.footer{
    background-color: #44454A;
    padding: 60px 0 40px;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        .nav-logo-wrapper{
            display: flex;
            align-items: center;
            .footer-logo{
                margin-right: 30px;
            }
        }
    }
    .nav-items{
        display: flex;
        list-style: none;
        margin: 0;
        padding-left: 30px;
        border-left: 1px solid rgba(255, 255, 255, 0.8);
        .nav-link:not(:last-child){
            margin-right: 40px;
        }
        a{
            text-decoration: none;
            color: #fff;
            opacity: 0.8;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .social-icons{
        display: flex;
        list-style: none;
        margin: 0;
        .social-link:not(:last-child){
            margin-right: 8px;
        }
        a{
            text-decoration: none;
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.3);
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .copywrite{
        color: #fff;
        opacity: 0.8;

        a{
            text-decoration: none;
            color: #fff;
            opacity: 0.8;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
@media (max-width: 1199px) {
    .footer {
        padding: 40px 0 20px;
        .container{
            flex-wrap: wrap;
            padding-bottom: 30px;
            margin-bottom: 20px;
            .nav-logo-wrapper{
                flex-direction: column;
                width: 100%;
                margin-bottom: 40px;
                .footer-logo {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
        }
        .nav-items{
            display: flex;
            list-style: none;
            margin: 0;
            padding-left: 0;
            border-left: none;
        }
        .social-icons{
            width: 100%;
            justify-content: center;
            padding: 0;
        }
    }
}
@media (max-width: 767px) {
.footer{
    padding: 30px 0 !important;
    .container{
        width: auto;
    }
    .nav-items{
        .nav-link:not(:last-child){
            margin-right: 20px;
        }
        a{
            font-size: 13px;
        }
    }
}
}