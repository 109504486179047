@import '../../styleResources/colors';
@import '../../styleResources/layouts';

.playerCard {
    text-decoration: none !important;
    position: relative;
    color: white;
    max-width: 100%;
    z-index: 1;

    &__notGraph {
        background-color: var(--background);
        padding: 20px;
        border-radius: 10px 10px 0 0;
        min-height: 170px;
    }

    &-employed{
        .playerCard__notGraph  {
            background-color:  var(--secondary-background); 
        }

        .playerCard__barGraphContainer { 
            border-left:  1px var(--secondary-background) solid;
            border-right: 1px var(--secondary-background) solid;
            border-bottom: 1px var(--secondary-background) solid;
        }
    } 

    &:hover {
        filter: drop-shadow(3px 3px 5px darken($elephant, 40%));
    }

    &__playerInfo{
        height: 50px;
    }

    &__settings {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__totalScore {
        display: flex;
        flex-direction: column;
    }

    &__points {
        font-weight: 800;
        padding-top: 10px;
        font-size: 40px;
    }
    &__name {
        font-size:  24px;
        font-weight: 800;
        display: inline-block;
    }
    &__options {
        display: flex;
    }

    &__leetCodeData {
        padding-top: 10px;
        div {
            padding-right: 10px;
            display: inline-block;
        }
        .leetCode {
            &__difficulty {
                padding: 0 5px;
            }
            &__difficulty, &__title {
                font-weight: 600;
            }
            &__loading {
                padding-right: 10px;
            }
        }
    }

    &__sync {
        margin-left: 30px;
    }
    &__codewarsInfo {
        @include two-column-grid;
    }

    &__employed {
        display: inline;
        background: linear-gradient(to right, rgba(235, 130, 0, 1), rgba(235, 130, 0, .3));
        box-shadow : -2px 2px rgba(10, 40, 140, .6);
        position: absolute;
        right: 0;
        top: 20px;
        padding: 5px 10px;
        color: white;
        height: 26px;
        line-height: 26px;
        font-weight: 600;
        &:before,  &:after{
            content: '';
            width: 0; 
            height: 0;
            position: absolute; 
            left: -17px;
            color: #eb8200;
            border-right-color: transparent;
            -webkit-filter: drop-shadow(-1px 2px rgba(10, 40, 140, .6));
            filter        : drop-shadow(-1px 2px rgba(10, 40, 140, .6));
            border-right-color: transparent;

        } 
        &:before {
            top: 0px;
            height: 0;
            border-top: 17px solid ;
            border-left: 17px solid transparent;
        } 
        &:after {
            top: 19px;
            height: 0;
            border-bottom: 17px solid ;
            border-left: 17px solid transparent;
        } 
    }

    &__position {
        font-weight: 700;
        font-size: 22px;
        vertical-align: bottom;
    }

    &__role, &__topScore {
        display: inline;
        background-color: $sunshine;
        position: absolute;
        left: 0;
        top: 10px;
        padding: 8px 10px 0 25px;
        color: black;
        height: 26px;
        line-height: 20px;
        
        &:after {
            content: '';
            width: 0; 
            height: 0;
            position: absolute; 
            right: -10px;
            top: 0px;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 10px solid $sunshine;
        }
    }

    &__topScore {
        background: linear-gradient(to right, rgba(var(--secondary-highlight), .3),  rgba(var(--secondary-highlight), 1));
        color: white;
        font-weight: 600;
        box-shadow : 2px 2px rgba(10, 40, 140, .6);
        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            border: 17px solid rgb(var(--secondary-highlight));
            right: -2em;
            border-left-width: 17px;
            border-right-color: transparent;
            -webkit-filter: drop-shadow(2px 2px rgba(10, 40, 140, .6));
            filter        : drop-shadow(2px 2px rgba(10, 40, 140, .6));
        }
    }

    &__clan {
        font-size: 12px;
        float: right;
        font-weight: 600;
    }

    &__label {
        font-size: 12px;
        font-weight: 800;
    }

    &__syncButton {
        border: 1px solid white;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        font-size: 15px;
        width: 72px;
        cursor: pointer;
    }

    &__codewarsUsername {
        font-size: 14px;
    }

    &__graphs {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        
    }

    &__openBarGraph {
        z-index: 3;
        font-size: 1.2em;
        margin-top: auto;
    }

    &__stats {
        width: 125px;
    }

    &__barGraphContainer {
        padding: 20px;
        border-radius: 0 0 10px 10px;
        background-color: white;
        border-left:  1px var(--background) solid;
        border-right: 1px var(--background) solid;
        border-bottom: 1px var(--background) solid;
        background-color: white;
    }

    @media only screen and (max-width: 440px) {
        width: 372px;
        margin: 0 auto;
    }
}